import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import pano0 from '../../data/Panorama/02_Entry.jpg'
import pano1 from '../../data/Panorama/03_Bedroom 2.jpg'
import pano2 from '../../data/Panorama/04_Bathroom.jpg'
import pano3 from '../../data/Panorama/05_Bedroom 1.jpg'
import pano4 from '../../data/Panorama/06-Deck.jpg'
import pano5 from '../../data/Panorama/07_Stair_top.jpg'
import pano6 from '../../data/Panorama/08_Living.jpg'
import pano7 from '../../data/Panorama/09_Study.jpg'
import pano8 from '../../data/Panorama/10_Balcony.jpg'

import loadable from '@loadable/component'

const Pannellum = loadable(() => import('pannellum-react').then(l => l.Pannellum))

const TestPanoPage = ({ data }) => {
  return (
    <Layout>
      <div className="container mx-auto px-2 my-10">
        <h1>Bathroom</h1>
        <Pannellum image={pano2} height="700px"></Pannellum>

        <h1>Entry</h1>
        <Pannellum image={pano0} height="700px"></Pannellum>

        <h1>Bedroom 1</h1>
        <Pannellum image={pano3} height="700px"></Pannellum>

        <h1>Bedroom 2</h1>
        <Pannellum image={pano1} height="700px"></Pannellum>

        <h1>Deck</h1>
        <Pannellum image={pano4} height="700px"></Pannellum>

        <h1>Stair top</h1>
        <Pannellum image={pano5} height="700px"></Pannellum>

        <h1>Living</h1>
        <Pannellum image={pano6} height="700px"></Pannellum>

        <h1>Study</h1>
        <Pannellum image={pano7} height="700px"></Pannellum>

        <h1>Balcony</h1>
        <Pannellum image={pano8} height="700px"></Pannellum>

        <h1>Diner</h1>
        <Pannellum image={data.pano9.childImageSharp.fixed.src} height="700px"></Pannellum>

        <h1>Powder</h1>
        <Pannellum image={data.pano10.childImageSharp.fixed.src} height="700px"></Pannellum>
      </div>
    </Layout>
  )
}

export default TestPanoPage

export const pageQuery = graphql`
  {
    pano9: file(relativePath: { eq: "Panorama/Diner_panorama.jpg" }) {
      childImageSharp {
        fixed(width: 4000, quality: 100) {
          src
        }
      }
    }
    pano10: file(relativePath: { eq: "Panorama/Powder panorama.jpg" }) {
      childImageSharp {
        fixed(width: 4000, quality: 100) {
          src
        }
      }
    }
  }
`
